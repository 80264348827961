import { IonBadge, IonCard, IonCardContent, IonCardTitle, IonIcon } from "@ionic/react";
import usePointerHandler, { Position, RotationContext, toolPadding, toolSize } from "../services/PointerHandler";
import SocketService from "../services/socket";
import "./DraggableElement.css";
import { chevronBackOutline } from "ionicons/icons";
import React, { useContext } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface DraggableProps {
    name: string;
    index: number;
    children: React.ReactNode;
    icon: IconDefinition;
    onSendUpdatedPosition: (position: Position) => void;
    badge?: string | number;
}

export function DraggableElement({ name, index, children, icon, onSendUpdatedPosition, badge }: DraggableProps) {

    const [ prevY, setPrevY ] = React.useState(0);
    const [ isAClick, setIsAClick ] = React.useState(true);

    const { 
        position, 
        setPosition, 
        setBoundingBox, 
        handleMouseDown, 
        handleMouseUp, 
        handleMouseMove,
    } = usePointerHandler(
        name,
        { x: toolSize+toolPadding, y: toolPadding, cursorX: 0, cursorY: 0, visible: false, hasMoved: false },
        onSendUpdatedPosition,
    );

    const boundingBoxRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (boundingBoxRef.current) {
            const width = boundingBoxRef.current.offsetWidth;
            const height = boundingBoxRef.current.offsetHeight;
            setBoundingBox({ x: width, y: height });
        }
    }, [boundingBoxRef.current]);

    React.useEffect(() => {
        SocketService.addElementPositionListener(name, (data) => {
            setPosition({...position, ...data});
        })
        return () => {
            SocketService.removeElementPositionListener(name);
        };
    }, []);

    const { itemOrder } = useContext(RotationContext);

    //console.log("name:", name, "itemOrder", index, itemOrder);
    const mouseDown = (e: React.MouseEvent | React.TouchEvent) => {
        const moveY = "touches" in e ? e.touches[0].clientY : e.clientY;
        setPrevY(moveY);
        setIsAClick(true);
    }

    const mouseUp = () => {
        if (isAClick) {
            setPosition({ ...position, visible: !position.visible });
        }
        setPrevY(0);
    }

    const mouseleave = () => {
        setIsAClick(false);
    }

    const mouseMove = (e: React.MouseEvent | React.TouchEvent) => {
        if (!isAClick) {
            return;
        }
        const moveY = "touches" in e ? e.touches[0].clientY : e.clientY;
        if (Math.abs(moveY - prevY) > 5) {
            setIsAClick(false);
        }
    }
    
    const o = itemOrder[index];
    const translate = `translate(${o > 0 ? 0 : '-100'}px, ${ ((o >= 0 ? o : itemOrder.length) - 1) * (toolSize+toolPadding) + toolPadding }px)`;

    if (!position.visible) {
        return (
            <div className="bookmark-button-wrapper">
                <div
                    className="bookmark-button-container"
                    style={{ 
                        height: `${toolSize}px`, 
                        top: `${toolPadding}px`,
                        transform: translate, 
                        left: "0px" 
                    }}
                    onMouseDown={mouseDown}
                    onMouseUp={mouseUp}
                    onMouseMove={mouseMove}
                    onMouseLeave={mouseleave}
                >
                    <div className="bookmark-button">
                        <FontAwesomeIcon className="minimized-icon" onClick={() => setPosition({ ...position, visible: true })} icon={icon} />
                    </div>
                    
                </div>
                {badge && (<div className="badge-container" style={{ top: `${toolPadding - 5}px`, left: "35px", transform: translate,  }}>
                    <IonBadge color="primary">{badge}</IonBadge>
                </div>)}
            </div>
        );
    }

    return (
        <IonCard className={`draggable-overlay`} style={{ top: position.y, left: position.x }}>
            <IonCardTitle
                className="draggable-overlay-title"
            >
                {name}
                <IonIcon
                    className="closeIcon"
                    icon={chevronBackOutline}
                    onClick={() => setPosition({...position, visible: false})}
                    style={{ cursor: "pointer" }}
                />
            </IonCardTitle>
            <div
                ref={boundingBoxRef}
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} 
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseMove}
                onMouseMove={handleMouseMove}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                onTouchMove={handleMouseMove}
            />
            <IonCardContent>{children}</IonCardContent>
        </IonCard>
    );
}
