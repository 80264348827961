// src/components/SlidingToolbar.tsx
import React, { useEffect } from "react";
import { IonActionSheet, IonButton, IonIcon } from "@ionic/react";
import { menuOutline } from "ionicons/icons";

interface DebugOverlayProps {
    onUserInfo: () => void;
    onOpenModal: () => void;
    onNewBoard: () => void;
}

const SlidingToolbar: React.FC<DebugOverlayProps> = (props) => {

  return (<>
    <IonButton color="dark" id="open-action-sheet" fill="clear" style={{ position: "absolute", top: "0.2em", right: "0.3em" }}>
      <IonIcon icon={menuOutline} slot="icon-only" />
    </IonButton> 
    <IonActionSheet
        trigger="open-action-sheet"
        header="Actions"
        buttons={[
          {
            text: 'My profile',
            handler: () => {
                props.onUserInfo();
            },
          },
          {
            text: 'Scan Board QR',
            role: 'destructive',
            handler: () => {
                props.onOpenModal();
            },
          },
          {
            text: 'Create New Board',
            handler: () => {
                props.onNewBoard();
            },
          },
          
          {
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ]}
      ></IonActionSheet></>
  );
};

export default SlidingToolbar;
