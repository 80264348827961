export default function drawLineGrid(ctx: CanvasRenderingContext2D, width: number, height: number, spacing: number, type = 0) {
    // Clear any existing drawings on the canvas
    ctx.clearRect(0, 0, width, height);

    // Set line color and properties
    ctx.strokeStyle = "#aaaaaa";
    ctx.lineWidth = 0.3;

    // Draw grid lines, either horizontal or vertical
    switch (type) {
        case 1:
            for (let y = 0; y < height; y += spacing) {
                ctx.beginPath();
                ctx.moveTo(0, y);
                ctx.lineTo(width, y);
                ctx.stroke();
            }
        break;
        default:
            for (let x = 0; x < width; x += spacing) {
                ctx.beginPath();
                ctx.moveTo(x, 0);
                ctx.lineTo(x, height);
                ctx.stroke();
            }
        break;
    }
}
