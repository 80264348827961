import React, { useState } from "react";
import {
  IonModal,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonInput,
  IonFooter,
  IonItem,
  IonLabel,
  IonList
} from "@ionic/react";

interface RequestUserInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (userInfo: { uuid: string, username: string; password?: string, newPassword?: string }) => void;
  currentUserInfo: { uuid: string, password: string | null, username: string };
}

const RequestUserInfoModal: React.FC<RequestUserInfoModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  currentUserInfo,
}) => {
  const [username, setUsername] = useState(currentUserInfo.username);
  const [password, setPassword] = useState(currentUserInfo.password);
  const [newPassword, setNewPassword] = useState('');

  React.useEffect(() => {
    if (isOpen) {
      setUsername(currentUserInfo.username);
      setPassword(currentUserInfo.password);
    }
  }, [isOpen, currentUserInfo]);
  const uuid = currentUserInfo.uuid;

  const handleSubmit = () => {
    if (!username || (!password === null && !newPassword) || (newPassword && newPassword.length < 5)) {
      return;
    }
    onSubmit({ uuid, username, password: password || undefined, newPassword });
    setNewPassword("");
    onClose();
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>User Information</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Name</IonLabel>
            <IonInput
              disabled={password === null}
              value={username}
              placeholder="Enter your name"
              onIonInput={(e) => setUsername(e.detail.value!)}
            />
          </IonItem>
          {password !== null ? (<IonItem>
            <IonLabel position="stacked">Password</IonLabel>
            <IonInput
              value={password}
              placeholder="Enter current password"
              type="password"
              onIonInput={(e) => setPassword(e.detail.value!)}
            />
          </IonItem>) : null}
          <IonItem>
            <IonLabel position="stacked">New password</IonLabel>
            <IonInput
              value={newPassword}
              placeholder="Enter new password"
              type="password"
              onIonInput={(e) => setNewPassword(e.detail.value!)}
            />
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonButton disabled={password === null && newPassword.length < 5} expand="full" color="primary" onClick={handleSubmit}>
          Save
        </IonButton>
        <IonButton expand="full" color="medium" onClick={onClose}>
          Close
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

export default RequestUserInfoModal;
