// src/components/ColorPaletteOverlay.tsx
import React from "react";
import "./ColorPaletteOverlay.css";
import { colorPaletteOutline } from "ionicons/icons";
import { DraggableElement } from "./DraggableElement";
import { faPalette } from "@fortawesome/free-solid-svg-icons";

interface ColorPaletteOverlayProps {
  onColorSelect: (color: string) => void;
  selectedColor: string;
}

interface ColorButtonProps {
  color: string;
  onSelect: (color: string) => void;
  selected: boolean;
}

const ColorButton: React.FC<ColorButtonProps> = ({ color, onSelect, selected }) => (
  <div
    className={`color-box${selected ? " selected" : ""}`}
    style={{ backgroundColor: color, cursor: "pointer", outlineColor: `${color}88` }}
    onClick={() => onSelect(color)}
  />
);

const ColorPaletteOverlay: React.FC<ColorPaletteOverlayProps> = ({ onColorSelect, selectedColor }) => {

  const colors = [
    "#FF5733", "#33FF57", "#3357FF", "#FFFF33", "#FF33FF",
    "#33FFFF", "#000000", "#FFFFFF", "#FFC300", "#DAF7A6"
  ];

  return (
    <DraggableElement
      index={4}
      name={"colorPaletteOverlay"}
      icon={faPalette}
      onSendUpdatedPosition={(_) => {return;}}
    >
        <div className="color-grid">
          {colors.map((color) => (
            <ColorButton
              key={color}
              color={color}
              onSelect={onColorSelect}
              selected={selectedColor === color}
            />
          ))}
        </div>
    </DraggableElement>
  );
};

export default ColorPaletteOverlay;
