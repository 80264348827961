export default function drawBoxGrid(ctx: CanvasRenderingContext2D, width: number, height: number, boxSize: number) {
    ctx.clearRect(0, 0, width, height);
    ctx.beginPath();
    ctx.lineWidth = 0.3;
    ctx.strokeStyle = "#aaaaaa";
    for (let x = 0; x < width; x += boxSize) {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, height);
    }
    for (let y = 0; y < height; y += boxSize) {
      ctx.moveTo(0, y);
      ctx.lineTo(width, y);
    }
    ctx.stroke();
}