import React, { useState, useRef, useEffect } from "react";
import "./DraggableCarousel.css";
import { RotationContext, useRotationHandler } from "../services/PointerHandler";
import { useScreenResize } from "./ScreenSizeContext";

import * as config from "../config.json";

const { toolPadding, toolSize } = config;

interface CarouselProps {
  items: React.ReactNode[];
}

function checkSwipeDirection(originY: number, moveY: number) {
    return originY > moveY ? -1 : 1;
}

function checkSwipeLength(originY: number, moveY: number) {
    return Math.abs(originY - moveY);
}

const Carousel: React.FC<CarouselProps> = ({ items }) => {
  
  const carouselRef = useRef<HTMLDivElement>(null);

  const { rotation, rotate, itemOrder, setLength, stepDegrees } = useRotationHandler(items.length);
  const [ isRotating, setIsRotating ] = useState(false);
  const screenSize = useScreenResize();
  const [ originY, setOriginY ] = useState(0);

    useEffect(() => {
        console.log('setting screen height');
        setLength(screenSize.height);
    }, [screenSize]);
   
    const mouseDown = (e: React.MouseEvent | React.TouchEvent) => {
        const _moveY = "touches" in e ? e.touches[0].clientY : e.clientY;
        setOriginY(_moveY);
        setIsRotating(true);
    }

    const mouseLeave = () => {
        setIsRotating(false);
        setOriginY(0);
    }

    const mouseMove = (e: React.MouseEvent | React.TouchEvent) => {
        if (!isRotating) return;
        const _moveY = "touches" in e ? e.touches[0].clientY : e.clientY;
        if (originY === 0) {
            setOriginY(_moveY);
            return;
        }
        const length = checkSwipeLength(originY, _moveY);
        const direction = checkSwipeDirection(originY, _moveY);
        if (length > 20) {
            rotate(direction * stepDegrees);
            setOriginY(0);
        }
    }

  return (
    <div
      ref={carouselRef}
      className="carousel-container"
      onMouseDown={mouseDown}
      onMouseMove={mouseMove}
      onMouseUp={mouseLeave}
    >
        <RotationContext.Provider value={{ rotation, rotate, isRotating, setIsRotating, itemOrder }}>
            {items.map((item, index) => (
                <div className="carousel-item" key={index} style={{
                    left: "0px", 
                    zIndex: items.length - index 
                }}>
                    {item}
                </div>
            ))}
        </RotationContext.Provider>
    </div>
  );
};

export default Carousel;
