import { DrawingToolProps, Point } from "./DrawTool";

export const usePaintbrush = (draw: (fromPoint: Point, toPoint: Point) => void): DrawingToolProps => {
    return {
        onStartDraw: (_: Point) => {return;},
        onDraw: (fromPoint: Point, toPoint: Point) => {
            draw(fromPoint, toPoint);
        },
        onEndDraw: (_: Point) => {return;}
    }
};