import { Device } from '@capacitor/device';

export interface UserData {
    uuid?: string;
    username: string;
    password?: string;
    newPassword?: string;
    email?: string;
    role?: string;
    status?: string;
}

export const getDeviceId = async () => {
    const info = await Device.getId();
    console.log(info);
    return info.identifier;
};
