import { useState } from "react";

export type Point = { x: number; y: number, layer?: number };

export interface DrawingToolProps {
    onStartDraw?: (startDrawPoint: Point) => void;
    onDraw?: (strokeStart: Point, strokeEnd: Point) => void;
    onEndDraw?: (endDrawPoint: Point) => void;
    snapToGrid?: boolean;
    gridSpacing?: number;
}

export const useDrawTool = (props: DrawingToolProps) => {

    const { onStartDraw, onDraw, onEndDraw, snapToGrid, gridSpacing } = props;
    const [isDrawing, setIsDrawing] = useState(false);
    const [lastX, setLastX] = useState(0);
    const [lastY, setLastY] = useState(0);
    const [currentPath, setCurrentPath] = useState<Point[]>([]);

    function snap(point: Point, gridSpacing: number): Point {
        return {
            x: Math.round(point.x / gridSpacing) * gridSpacing,
            y: Math.round(point.y / gridSpacing) * gridSpacing,
        };
    }
    
    const startDraw = (event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
        const { clientX, clientY } = 'touches' in event ? event.touches[0] : event;
        const { x, y } = snapToGrid ? snap({ x: clientX, y: clientY }, gridSpacing || 10) : { x: clientX, y: clientY }; 
        onStartDraw && onStartDraw({ x, y });
        setIsDrawing(true);
        setLastX(x);
        setLastY(y);
        setCurrentPath([{ x, y }]); // Start a new path
    };

    const draw = (event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
        if (!isDrawing) return;
        const { clientX, clientY } = 'touches' in event ? event.touches[0] : event;
        const { x, y } = snapToGrid ? snap({ x: clientX, y: clientY }, gridSpacing || 10) : { x: clientX, y: clientY }; 
        
        onDraw && onDraw({ x: lastX, y: lastY }, { x, y });
        // Update last position
        setLastX(x);
        setLastY(y);
        // Add the current point to the path
        addPoint({ x, y });
    };
    
    const endDraw = () => {
        const { x, y } = snapToGrid ? snap({ x: lastX, y: lastY }, gridSpacing || 10) : { x: lastX, y: lastY }; 
        setIsDrawing(false);
        onEndDraw && onEndDraw({ x, y });
        if (currentPath.length > 0) {
          clearPath();
        }
    };

    const stopDraw = () => {
        setIsDrawing(false);
        onEndDraw && onEndDraw({ x: lastX, y: lastY });
        if (currentPath.length > 0) {
          clearPath();
        }
    }
    
    const addPoint = (point: Point) => {
        setCurrentPath([...currentPath, point]);
    };
    
    const clearPath = () => {
        setCurrentPath([]);
    };
    
    return {
        startDraw,
        draw,
        endDraw,
        stopDraw,
        isDrawing,
        currentPath,
        lastPoint: currentPath[currentPath.length - 1],
        addPoint,
        clearPath,
    };
};