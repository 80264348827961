// src/components/ToolSelectorOverlay.tsx
import React from "react";
import "./ToolSelectorOverlay.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DraggableElement } from "./DraggableElement";
import { IconDefinition, IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { faBrush, faCircle, faDrawPolygon, faEnvelopeOpenText, faEraser, faFileText, faSquare, faStickyNote, faTextWidth, faVectorSquare } from '@fortawesome/free-solid-svg-icons'

interface ToolSelectorOverlayProps {
  onToolSelect: (tool: number) => void;
  selectedTool: number;
  setEraser: (eraser: boolean) => void;
    eraser: boolean;
}

interface ToolButtonProps {
  tool: number;
  icon: IconDefinition;
  onSelect: (tool: number) => void;
  selected: boolean;
}

const ToolButton: React.FC<ToolButtonProps> = ({ tool, icon, onSelect, selected }) => (
  <div className={`tool-box${selected ? " selected" : ""}`} onClick={() => onSelect(tool)}>
    <FontAwesomeIcon icon={icon} />
  </div>
);

const ToolSelectorOverlay: React.FC<ToolSelectorOverlayProps> = ({ onToolSelect, selectedTool, setEraser, eraser }) => {

  const tools = [
    { tool: 0, icon: faBrush },
    { tool: 1, icon: faDrawPolygon },
    { tool: 2, icon: faVectorSquare },
    { tool: 3, icon: faCircle },
    { tool: 4, icon: faStickyNote },
    { tool: 5, icon: faFileText },
    { tool: 6, icon: faEnvelopeOpenText },
  ] as { tool: number; icon: IconDefinition }[];

  return (
    <DraggableElement index={2} name="toolSelector" icon={tools[selectedTool].icon} onSendUpdatedPosition={(_) => {return;}}>
      <div className="tool-grid">
        {tools.map((tool) => (
          <ToolButton
            key={tool.tool}
            tool={tool.tool}
            icon={tool.icon}
            onSelect={onToolSelect}
            selected={selectedTool === tool.tool}
          />
        ))}
          <ToolButton
              key={"erase"}
              tool={0}
              icon={faEraser}
              onSelect={() => setEraser(!eraser)}
              selected={eraser}
          />
      </div>
    </DraggableElement>
  );
};

export default ToolSelectorOverlay;
