import { Point } from "../services/DrawTool";

export const useShadowPlot = (ctx?: CanvasRenderingContext2D | null, width?: number, height?: number) => (points: Point[], color = "#555555", lineDash = [5,5]) => {
    if (!ctx || !width || !height) return;
    ctx.clearRect(0, 0, width, height);
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    ctx.lineWidth = 1;
    ctx.setLineDash(lineDash);
    for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i].x, points[i].y);
    }
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
}