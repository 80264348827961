// src/components/QrCodeOverlay.tsx
import React, { useState } from "react";
import QRCode from "qrcode";
import "./QrCodeOverlay.css";
import { DraggableElement } from "./DraggableElement";
import { Clipboard } from "@capacitor/clipboard";
import useToastService from "../services/ToastService";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import config from "../config.json";

interface QrCodeOverlayProps {
  boardId: string;
}

const QrCodeOverlay: React.FC<QrCodeOverlayProps> = ({ boardId }) => {

    const [src, setSrc] = useState<string>("");

    const { presentToast } = useToastService();

    React.useEffect(() => {
        if (boardId) {
        QRCode.toDataURL(boardId)
            .then((url) => {
            setSrc(url);
            })
            .catch((err) => {
            console.error("Failed to generate QR code:", err);
            });
        }
    }, [boardId]);

    const writeToClipboard = async () => {
        await Clipboard.write({
            url: config.linkUrl + "/" + boardId,
          label: "Board ID"
        });
        presentToast("Board ID copied to clipboard");
    };

    return src ? (
        <DraggableElement 
            name="qrCodeOverlay"
            index={0}
            icon={faQrcode}
            onSendUpdatedPosition={(_) => {return;}}
        >
            <img className="qr-code-overlay-img" src={src} alt="QR Code" onClick={writeToClipboard} />
        </DraggableElement>
    ) : null;
};

export default QrCodeOverlay;
