// src/components/LineWidthSelectorOverlay.tsx
import React from "react";
import "./LineWidthSelectorOverlay.css";
import { barbellOutline } from "ionicons/icons";
import { DraggableElement } from "./DraggableElement";
import { faPencilRuler } from "@fortawesome/free-solid-svg-icons";

interface LineWidthSelectorOverlayProps {
  onLineWidthSelect: (width: number) => void;
  selectedWidth: number;
}

interface LineWidthButtonProps {
  width: number;
  displayWidth: number;
  onSelect: (width: number) => void;
  selected: boolean;
}

const LineWidthButton: React.FC<LineWidthButtonProps> = ({ width, displayWidth, onSelect, selected }) => (
  <div
    className={`line-width-box${selected ? " selected" : ""}`}
    style={{ height: `${displayWidth * 2}px`, width: `${displayWidth * 2}px` }}
    onClick={() => onSelect(width)}
  >
    <span>{width}</span>
  </div>
);

const LineWidthSelectorOverlay: React.FC<LineWidthSelectorOverlayProps> = ({ onLineWidthSelect, selectedWidth }) => {

  const widths = [1, 2, 4, 6, 8, 10, 12, 15, 20]; // Define available line widths
  const displayWidths = [8, 9, 10, 11, 12, 13, 14, 15, 16]; // Define display widths
  
  return (
    <DraggableElement 
      index={3} 
      name="lineWidthSelector" 
      icon={faPencilRuler} 
      onSendUpdatedPosition={(_) => {return;}}
    >
      <div className="line-width-grid">
        {widths.map((width, idx) => (
          <LineWidthButton
            key={width}
            width={width}
            displayWidth={displayWidths[idx]}
            onSelect={onLineWidthSelect}
            selected={selectedWidth === width}
          />
        ))}
      </div>
    </DraggableElement>
  );
};

export default LineWidthSelectorOverlay;
