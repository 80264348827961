import { Point } from "../services/DrawTool";
import React from "react";
import * as config from "../config.json";
import { point2b } from "./byteTools";

export const { chunkSize } = config;

export type Chunk = {
    x: number; // x position of the chunk
    y: number; // y position of the chunk
};

export type MappedChunkData = {
    id: Uint8Array; // Unique ID of the chunk
    data: Uint8Array; // Data of the chunk
};

const modifiedChunks: Uint8Array[] = [];

type ChunkQueueProcessor = (chunks: MappedChunkData[]) => void;

export const useModifiedChunks = () => {

    const addModifiedChunk = (chunk: Point) => {
        modifiedChunks.push(point2b(chunk));
    }

    const dumpModifiedChunks = (queueChunks: ChunkQueueProcessor, processChunks: (chunks: Uint8Array) => MappedChunkData) => {
        if (modifiedChunks.length === 0) return [];
        const _modifiedChunks = modifiedChunks.splice(0, modifiedChunks.length);
        const uniqueChunks = _modifiedChunks.filter((chunk, index, self) => self.indexOf(chunk) === index);
        queueChunks(uniqueChunks.map(processChunks));
    }

    return { addModifiedChunk, dumpModifiedChunks };
}

export const chunkPlot = (ctx: CanvasRenderingContext2D, point: Chunk) => {
    const { x, y } = point;
    const x1 = x * chunkSize;
    const x2 = x1 + chunkSize;
    const y1 = y * chunkSize;
    const y2 = y1 + chunkSize;
    
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x1, y2);
    ctx.lineTo(x2, y2);
    ctx.lineTo(x2, y1);
    ctx.lineTo(x1, y1);
    ctx.lineWidth = 1;
    ctx.strokeStyle = "#ff0000";
    ctx.stroke();
}