import { Point } from "../services/DrawTool";

export function b2int(array: Uint8Array): number {
    if (array.length !== 5) {
        throw new Error("Expected a Uint8Array of length 5.");
    }

    // Combine the 5 bytes manually
    return (array[0] * 2**32) + (array[1] * 2**24) + (array[2] * 2**16) + (array[3] * 2**8) + array[4];
}

export function int2b(int: number): Uint8Array {
    if (int < 0 || int > 0xFFFFFFFFFF) {
        throw new Error("Expected an integer in the range 0 to 1099511627775 (40-bit unsigned integer).");
    }

    // Split the 40-bit integer into 5 bytes
    const buffer = new ArrayBuffer(5);
    const view = new DataView(buffer);
    view.setUint8(0, (int & 0xFF00000000) >>> 32);
    view.setUint8(1, (int & 0x00FF000000) >>> 24);
    view.setUint8(2, (int & 0x0000FF0000) >>> 16);
    view.setUint8(3, (int & 0x000000FF00) >>> 8);
    view.setUint8(4, int & 0x00000000FF);

    return new Uint8Array(buffer);
}
// pack two integers into a single Uint8Array
export function point2b(point: Point): Uint8Array {
    const { layer = 0, x, y } = point;
    if (layer < 0 || layer > 0xFF) {
        throw new Error("Layer must be in the range 0 to 255 (8-bit unsigned integer)");
    }
    if (x < 0 || x > 0xFFFF || y < 0 || y > 0xFFFF) {
        throw new Error("Both position integers must be in the range 0 to 65535 (16-bit unsigned integer)");
    }

    const buffer = new ArrayBuffer(5); // 5 bytes to store two 16-bit integers and one 8-bit integer
    const view = new DataView(buffer);
    // Store each integer as a 16-bit unsigned integer (2 bytes each)
    view.setUint8(0, layer);
    view.setUint16(1, x, true); // true for little-endian
    view.setUint16(3, y, true); // true for little-endian

    return new Uint8Array(buffer);
}

// unpack two integers from a single Uint8Array
export function b2point(buffer: Uint8Array): Point {
    
    const view = new DataView(buffer.buffer);
    if (buffer.byteLength !== 5) {
        console.error('Buffer:', buffer, 'Length:', buffer.byteLength);
        throw new Error("Buffer must be 4 bytes long to unpack two 16-bit unsigned integers");
    }

    // Read each integer as a 16-bit unsigned integer (2 bytes each)
    const layer = view.getUint8(0);
    const x = view.getUint16(1, true); // true for little-endian
    const y = view.getUint16(3, true); // true for little-endian
    return { layer, x, y };
}