import React from 'react';

interface CustomBrushCursorProps {
    visible: boolean;
    mouseX: number;
    mouseY: number;
    selectedWidth: number;
    eraser: boolean;
}

const CustomBrushCursor = ({ visible, mouseX, mouseY, selectedWidth, eraser }: CustomBrushCursorProps) => {
    return visible ? (
        <div
            style={{
                position: 'absolute',
                left: mouseX - selectedWidth / 2,
                top: mouseY - selectedWidth / 2,
                width: selectedWidth,
                height: selectedWidth,
                borderRadius: '50%',
                border: `1px solid ${eraser ? "#FF0000" : "#000000"}`,
                pointerEvents: 'none', // Prevents interfering with canvas events
                backgroundColor: eraser ? "rgba(255, 0, 0, 0.2)" : "transparent",
            }}
        ></div>
    ) : null;
}

export default CustomBrushCursor;