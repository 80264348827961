// src/components/LayerSelectionOverlay.tsx
import React from "react";
import "./LayerSelectionOverlay.css";
import { layersOutline } from "ionicons/icons";
import { DraggableElement } from "./DraggableElement";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

interface LayerSelectionOverlayProps {
  boardId: string;
  onLayerSelect: (layer: number) => void;
  selectedLayer: number;
}

interface LayerSelectorButtonProps {
  layer: number;
  onSelect: (layer: number) => void;
  selected: boolean;
}

const LayerSelectorButton: React.FC<LayerSelectorButtonProps> = ({ 
  layer, 
  onSelect,
  selected 
}) => {
  return (
    <div className={`layer-box${selected ? " selected" : ""}`} onClick={(e: React.MouseEvent | React.TouchEvent) => {
      e.stopPropagation();
      e.preventDefault();
      onSelect(layer);
    }}>
      {layer}
    </div>
  );
}

const LayerSelectionOverlay: React.FC<LayerSelectionOverlayProps> = ({ boardId, onLayerSelect, selectedLayer }) => {

  const handleLayerSelect = (layer: number) => {
    onLayerSelect(layer);
  };

  return (
    <DraggableElement
      index={1}
      name="layerSelectionOverlay"
      icon={faLayerGroup}
      onSendUpdatedPosition={(_) => {return;}} 
    >
        <div className="layer-grid">
          {Array.from({ length: 9 }).map((_, index) => (
            <LayerSelectorButton 
              key={index}
              selected={selectedLayer === index + 1}
              layer={index + 1} 
              onSelect={handleLayerSelect} 
            />
          ))}
        </div>
    </DraggableElement>
  );
};

export default LayerSelectionOverlay;
