// src/components/BackgroundGridToolOverlay.tsx
import React from "react";
import "./BackgroundGridToolOverlay.css";
import { gridOutline } from "ionicons/icons";
import { DraggableElement } from "./DraggableElement";
import { IconDefinition, faBorderAll, faBorderNone, faDotCircle, faGraduationCap, faGripLines, faGripLinesVertical, faListDots, faSquare, faTh, faThLarge } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface BackgroundGridToolOverlayProps {
  onGridSelect: (gridType: number) => void;
  onSnapToGrid: (snapToGrid: boolean) => void;
  selectedGrid: number;
  snapToGrid: boolean;
}

interface GridButtonProps {
  gridType: number;
  icon: IconDefinition;
  onSelect: (gridType: number) => void;
  selected: boolean;
}

const GridButton: React.FC<GridButtonProps> = ({ gridType, icon, onSelect, selected }) => (
  <div className={`grid-box${selected ? " selected" : ""}`} onClick={() => onSelect(gridType)}>
    <FontAwesomeIcon icon={icon} />
  </div>
);

const BackgroundGridToolOverlay: React.FC<BackgroundGridToolOverlayProps> = ({ onGridSelect, onSnapToGrid, selectedGrid, snapToGrid }) => {

    const grids = [
        { gridType: 0, icon: faSquare },
        { gridType: 1, icon: faBorderAll },
        { gridType: 2, icon: faBorderAll },
        { gridType: 3, icon: faBorderNone },
        { gridType: 4, icon: faBorderNone },
        { gridType: 5, icon: faGripLines },
        { gridType: 6, icon: faGripLinesVertical },
    ];

    return (
        <DraggableElement index={5} name="backgroundGridTool" icon={faBorderNone} onSendUpdatedPosition={(_) => {return;}}>
            <div className="grid-selector">
            {grids.map((grid) => (
                <GridButton
                  key={grid.gridType}
                  gridType={grid.gridType}
                  icon={grid.icon}
                  onSelect={onGridSelect}
                  selected={selectedGrid === grid.gridType}
                />
            ))}
            <GridButton
                gridType={7}
                icon={faGraduationCap}
                onSelect={() => selectedGrid > 0 && onSnapToGrid(!snapToGrid)}
                selected={snapToGrid}
            />  
            </div>
        </DraggableElement>
    );
};

export default BackgroundGridToolOverlay;
