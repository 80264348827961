import React from "react";
import "./UserViewingOverlay.css";
import { faCrown, faUsers } from "@fortawesome/free-solid-svg-icons";
import { DraggableElement } from "./DraggableElement";
import { ViewerData, ViewerDataItem } from "../services/socket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface UserViewingOverlayProps {
  viewers: ViewerData;
}

interface UserIconProps {
    admin: boolean;
}

const UserIcon = ({admin}: UserIconProps) => {
    if (admin) {
        return (
            <FontAwesomeIcon icon={faCrown} />
        );
    }
    return (
        <FontAwesomeIcon icon={faUsers} />
    );
}

const UserItem: React.FC<{ user: ViewerDataItem }> = ({ user }) => (
  <div className={`user-item${user.active ? " active" : ""}`}>
    <span className={`user-avatar${user.owner ? " owner" : ""}`}><UserIcon admin={user.owner} /></span>
    <span className={`user-name`}>{user.username}</span>
  </div>
);

const UserViewingOverlay: React.FC<UserViewingOverlayProps> = ({ viewers }) => {

    const viewerCount = Object.keys(viewers).length;

    return (
        <DraggableElement
            index={6}
            name={"userViewingOverlay"}
            icon={faUsers}
            badge={Object.keys(viewers).length.toString()}
            onSendUpdatedPosition={(_) => {return;}}
        >
        <div className="user-list">
            {Object.keys(viewers).slice(0, 10).map((userId) => (
            <UserItem key={userId} user={viewers[userId]} />
            ))}
            {Object.keys(viewers).length === 0 && <div className="no-users">No users viewing this board</div>}
            <span className="user-count">{viewerCount <= 10 ? `Total ${viewerCount} viewers` : `...and ${viewerCount-10} more`}</span>
        </div>
        </DraggableElement>
    );
};

export default UserViewingOverlay;
