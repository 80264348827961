export default function drawDotGrid(ctx: CanvasRenderingContext2D, width: number, height: number, spacing: number, dotRadius = 1) {
    // Clear any existing drawings on the canvas
    ctx.clearRect(0, 0, width, height);
    
    // Set dot color and properties
    ctx.fillStyle = "#aaaaaa";
    
    for (let x = spacing / 2; x < width; x += spacing) {
        for (let y = spacing / 2; y < height; y += spacing) {
            // Draw a dot at each grid point
            ctx.beginPath();
            ctx.arc(x, y, dotRadius, 0, Math.PI * 2);
            ctx.fill();
        }
    }
}
