import { useIonToast } from "@ionic/react";

type ToastPosition = 'top' | 'middle' | 'bottom';

const useToastService = () => {
    
    const [present] = useIonToast();

    const presentToast = (message: string, position: ToastPosition = "top") => {
        present({
        message,
        duration: 1500,
        position: position,
        });
    };
    
    return {presentToast};
}

export default useToastService;