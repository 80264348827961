import React, { useEffect, useState } from "react";
import { Point } from "./DrawTool";
import * as config from "../config.json";

export const { toolPadding, toolSize } = config;

export interface Position {
    x: number;
    y: number;
    cursorX: number;
    cursorY: number;
    visible: boolean;
    hasMoved: boolean;
}

type Box = [Point, Point, Point, Point];
type Hit = { x: boolean, y: boolean };

const boundingBoxes: { [key: string]: Point } = {};
const reservedAreas: { [key: string]: Box } = {};

// Check for overlap on the x-axis
// [x1_min, x1_max, y1_min, y1_max]

function getAxisCollisions(b1: Box, b2: Box): Hit {
    // Get min/max x and y for Box 1
    const b1_minX = Math.min(b1[0].x, b1[1].x, b1[2].x, b1[3].x);
    const b1_maxX = Math.max(b1[0].x, b1[1].x, b1[2].x, b1[3].x);
    const b1_minY = Math.min(b1[0].y, b1[1].y, b1[2].y, b1[3].y);
    const b1_maxY = Math.max(b1[0].y, b1[1].y, b1[2].y, b1[3].y);

    // Get min/max x and y for Box 2
    const b2_minX = Math.min(b2[0].x, b2[1].x, b2[2].x, b2[3].x);
    const b2_maxX = Math.max(b2[0].x, b2[1].x, b2[2].x, b2[3].x);
    const b2_minY = Math.min(b2[0].y, b2[1].y, b2[2].y, b2[3].y);
    const b2_maxY = Math.max(b2[0].y, b2[1].y, b2[2].y, b2[3].y);

    // Check for overlap on the x-axis and y-axis
    const xCollision = b1_minX <= b2_maxX && b1_maxX >= b2_minX;
    const yCollision = b1_minY <= b2_maxY && b1_maxY >= b2_minY;

    return { x: xCollision, y: yCollision };
}

export function useRotationHandler(itemcount: number) {

    const [ delta, setDelta ] = useState(0);
    const [ step, setStep ] = useState(20);
    const [ length, setLength ] = useState(0);
    const [ rotation, setRotation ] = useState(0);

    const { stepDegrees } = React.useMemo(() => {
        return { stepDegrees: 360 / itemcount };
    }, [step, length]);

    function rotate(y: number) {
        setDelta(delta + y);
        if (Math.abs(delta) > step) {
            const newRotation = rotation + (delta > 0 ? stepDegrees : -stepDegrees);
            if (newRotation < 0) {
                setRotation(Math.abs(360 + newRotation));
            } else {
                setRotation(Math.abs(newRotation % 360));
            }
            setDelta(0);
        }
    }

    function _checkOrder(index: number, height: number) {
        const stepDegree = (index) * stepDegrees;
        const notch = Math.round(((stepDegree + rotation) % 360) / stepDegrees);
        const fitTools = Math.floor(height / (toolSize + toolPadding) - 1);
        //console.log("index", index, "stepdegree", stepDegree, "notch", notch, "fitTools", fitTools, "rotation", rotation, "toolsize", toolSize, "toolPadding", toolPadding);
        if (notch > fitTools) {
            return -1;
        } else {
            return notch;
        }
    }

    const itemOrder = React.useMemo(() => {
        const order = [];
        for (let i = 0; i < itemcount; i++) {
            order.push(_checkOrder(i, length));
        }
        return order;
    }, [rotation, length]);

    return { rotation, rotate, setStep, setLength, stepDegrees, itemOrder };
}

export interface RotationContextProps {
    rotation: number;
    rotate: (y: number) => void;
    isRotating: boolean;
    setIsRotating: (v: boolean) => void;
    itemOrder: number[];
}

export const RotationContext = React.createContext({ 
    rotation: 0, 
    rotate: (_: number) => {return;}, 
    isRotating: false, 
    setIsRotating: (v: boolean) => {return;}, 
    itemOrder: [] 
} as RotationContextProps);

function usePointerHandler(name: string, initialPosition: Position, sendUpdatedPosition?: (position: Position) => void) {

    const [isDragging, setIsDragging] = useState(false);
    const [mouseDownPosition, setMouseDownPosition] = useState({ x: 0, y: 0 });
    const [position, setPosition] = useState(initialPosition);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

    const setBoundingBox = (boundingBox: Point) => {
        boundingBoxes[name] = boundingBox;
    }

    function _setPosition(newPosition?: Position) {
        if (!newPosition) {
            setPosition(initialPosition);
            return;
        }
        sendUpdatedPosition && sendUpdatedPosition(newPosition);
        setPosition(newPosition);
    }

    useEffect(() => {
        if (!boundingBoxes[name] || (boundingBoxes[name] && !position.visible && boundingBoxes[name].x !== 50 && boundingBoxes[name].y !== 50)) {
            setBoundingBox({ x: 50, y: 50 });
        }
    }, [position]);

    const handleMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        setMouseDownPosition({ x: "touches" in e ? e.touches[0].clientX : e.clientX, y: "touches" in e ? e.touches[0].clientY : e.clientY });
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    
        if ((position.hasMoved && position.x < -50 && position.visible)) {
            _setPosition({
                ...position,
                x: position.x < -50 ? -20 : position.x,
                visible: !position.visible,
                cursorX: 0,
                cursorY: 0,
                hasMoved: false,
            });
            return;
        }
    
        _setPosition({
          ...position,
          cursorX: 0,
          cursorY: 0,
          hasMoved: false
        });
    };

    const handleMouseMove = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        
        if (!isDragging) return;
    
        if (position.cursorX === 0 || position.cursorY === 0) {
            setPosition({
                ...position,
                cursorX: "touches" in e ? e.touches[0].clientX : e.clientX,
                cursorY: "touches" in e ? e.touches[0].clientY : e.clientY,
            });
            return;
        }
    
        const newCursorX = "touches" in e ? e.touches[0].clientX : e.clientX;
        const newCursorY = "touches" in e ? e.touches[0].clientY : e.clientY;
    
        const xMovement = newCursorX - position.cursorX;
        const yMovement = newCursorY - position.cursorY;
    
        const _hasMoved = (
            mouseDownPosition.x - newCursorX > 5 || 
            mouseDownPosition.y - newCursorY > 5 ||
            mouseDownPosition.x - newCursorX < -5 ||
            mouseDownPosition.y - newCursorY < -5
        );

        const wantedPosition: Box = [
            { x: position.x + xMovement, y: position.y + yMovement },
            { x: position.x + xMovement + boundingBoxes[name].x, y: position.y + yMovement },
            { x: position.x + xMovement, y: position.y + yMovement + boundingBoxes[name].y },
            { x: position.x + xMovement + boundingBoxes[name].x, y: position.y + yMovement + boundingBoxes[name].y }
        ]

        Object.keys(reservedAreas).forEach((key) => {
            const reservedArea = reservedAreas[key];
            const collisions = getAxisCollisions(reservedArea, wantedPosition);
           
        });
    
        setPosition({
            ...position,
            x: position.visible ? position.x + xMovement : position.x, // Offset for the center of the QR code
            y: position.y + yMovement,
            cursorX: newCursorX,
            cursorY: newCursorY,
            hasMoved: _hasMoved
        });
    
    };
    
    if (boundingBoxes[name]) {
        reservedAreas[name] = [
            { x: position.x - toolPadding, y: position.y - toolPadding},
            { x: position.x + boundingBoxes[name].x + toolPadding, y: position.y - toolPadding},
            { x: position.x - toolPadding, y: position.y + boundingBoxes[name].y + toolPadding},
            { x: position.x + boundingBoxes[name].x + toolPadding, y: position.y + boundingBoxes[name].y + toolPadding},
        ];
    }

    return {
        position,
        setPosition: _setPosition,
        setBoundingBox,
        handleMouseDown,
        handleMouseUp,
        handleMouseMove
    };
}

export default usePointerHandler;