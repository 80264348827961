import { Point } from "../services/DrawTool";

export interface BoundingBox {
    start: Point;
    end: Point;
}

export const useContextPlot = (ctx?: CanvasRenderingContext2D | null, onModified?: (bb: BoundingBox[]) => void) => (color: string, lineWidth: number, points: Point[], erase = false) => {
    if (!ctx) return;
    
    if (erase) {
      ctx.globalCompositeOperation = "destination-out";
    } else {
      ctx.globalCompositeOperation = "source-over";
    }

    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    const lw = lineWidth / 2;

    const affectedAreas = [];
    let lastPoint = points[0];

    for (let i = 1; i < points.length; i++) {
      affectedAreas.push({ start: {x: lastPoint.x - lw, y: lastPoint.y - lw}, end: {x: points[i].x + lw, y: points[i].y + lw}})
      ctx.lineTo(points[i].x, points[i].y);
      lastPoint = points[i];
    }
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
    onModified && onModified(affectedAreas);
  };