// src/components/RequestBoardIdModal.tsx
import React from "react";
import {
  IonModal,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonInput,
  IonFooter
} from "@ionic/react";
import QrReader from "./QrReader";

interface RequestBoardIdModalProps {
  currentBoardId: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (boardId: string) => void;
}

const RequestBoardIdModal: React.FC<RequestBoardIdModalProps> = ({ isOpen, onClose, onSubmit, currentBoardId }) => {

  const modal = React.useRef<HTMLIonModalElement>(null);

  React.useEffect(() => {
    if (isOpen) {
      modal.current?.present();
    } else {
      modal.current?.dismiss();
    }
  }, [isOpen]);

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Scan Board ID</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isOpen && (<QrReader onScan={(boardId: string) => boardId !== currentBoardId && onSubmit(boardId)} />)}
      </IonContent>
      <IonFooter>
        <IonButton expand="full" color="medium" onClick={onClose}>Cancel</IonButton>
      </IonFooter>
    </IonModal>
  );

};

export default RequestBoardIdModal;
