import { useState } from "react";
import { DrawingToolProps, Point } from "./DrawTool";

interface LineBrushProps {
    draw: (startPoint: Point, endPoint: Point) => void;
    shadowDraw: (fromPoint: Point, toPoint: Point) => void;
}

let startingPoint: Point = { x: 0, y: 0 };

export const useLineBrush = ({ draw, shadowDraw }: LineBrushProps): DrawingToolProps => {
    
    return {
        onStartDraw: (sp) => startingPoint = sp,
        onDraw: (_: Point, toPoint: Point) => {
            shadowDraw(startingPoint, toPoint);
        },
        onEndDraw: (endingPoint) => {
            draw(startingPoint, endingPoint);
        }
    }
}