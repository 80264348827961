import React, { createContext, useContext, useEffect, useState } from "react";

// Define the context shape
interface ScreenSize {
  width: number;
  height: number;
}

// Create the context
const ScreenResizeContext = createContext<ScreenSize | undefined>(undefined);

// Context provider component
export const ScreenResizeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [screenSize, setScreenSize] = useState<ScreenSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ScreenResizeContext.Provider value={screenSize}>
      {children}
    </ScreenResizeContext.Provider>
  );
};

// Custom hook to use the context
export const useScreenResize = (): ScreenSize => {
  const context = useContext(ScreenResizeContext);
  if (!context) {
    throw new Error("useScreenResize must be used within a ScreenResizeProvider");
  }
  return context;
};
